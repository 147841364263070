<template>
<div class="mobile-menu" v-bind:class="{ 'hidden-mobile': !showComponent }">
    <div class="mobile-menu__buttons">
        <a href="/">
        <img src="@/assets/images/LogoColor.png" alt="Getbuck" width="150" height="50">
        </a>
    </div>
    <menu-component/>
    <ModalComponent ref="modal" />
</div>
</template>

<script>
    import MenuComponent from '@/components/menu.vue'
    import ModalComponent from '@/components/modal.vue'
    export default {
        name: 'MobileMenu',
        showComponent: true,
        components: {
            MenuComponent,
            ModalComponent
        },
        methods: {
            openModal() {
                this.$refs.modal.openModal();
            }
        },
        props: {
            items: {
            type: Array,
            required: true
            }
        },
        data() {
            return {
            isOpen: false
            }
        }
    };
</script>