<template>
  <footer class="footer">

    <div class="footer-content">
      <section class="agent-buttons">
            <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agents classic</a>
      </section>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
  </footer>
  <!-- <div class="footer-copyright">
    Wager Your Mojo © 2023 TERMS AND CONDITIONS
  </div> -->
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      
      const agentClassic = "https://agents.betwcs.online";
      const agentNew = "https://adm.getbuck.net/";
      return {
        agentClassic,
        agentNew,
        routes: [
        //  {
        //     name       :  'sportsbook',
        //     route      :  'sportsbook'
        //   },
          // {
          //   name       :  'live betting',
          //   route      :  'live-betting'
          // },
          // {
          //   name       :  'racebook',
          //   route      :  'racebook'
          // },
          // {
          //   name       :  'casino',
          //   route      :  'casino'
          // },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ]
      }
    },
  };
</script>
